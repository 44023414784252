<template>
  <action-add-new
    :action-url="'product-extra-options/categories/' + category.id + '/options'"
    button-label="Adauga extra optiune noua">

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.internal_name" label="Denumire interna"/>
        <error-messages :errors="errors.internal_name"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.public_name" label="Denumire publica"/>
        <error-messages :errors="errors.public_name"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.price" label="Pret"/>
        <error-messages :errors="errors.price"></error-messages>
      </div>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages,
    ActionAddNew
  },
  props: {
    category: Object
  }
}
</script>
