<template>
  <action-edit
    :action-url="`product-extra-options/categories/${category.id}/options/${item.id}`"
    :item="item"
    button-label="Editeaza Extra Optiune"
  >
    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.internal_name" label="Denumire interna"/>
        <error-messages :errors="errors.internal_name"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.public_name" label="Denumire publica"/>
        <error-messages :errors="errors.public_name"></error-messages>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.price" label="Pret"/>
        <error-messages :errors="errors.price"></error-messages>
      </div>
    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    },
    category: {
      require: true,
      type: Object
    }
  }
}

</script>
