<template>
  <div>
    <p>Detalii categorie {{ category.internal_name }}</p>
    <cit-data-table
      :headers="headers"
      :resource-path="
        'product-extra-options/categories/' + $route.params.id + '/options'
      "
    >
      <template v-slot:actions>
        <create-form :category="category" />
      </template>

      <template v-slot:item.actions="{ item }">
        <edit-form :item="item" :category="category" />
        <action-delete
          :action-url="`product-extra-options/categories/${category.id}/options/${item.id}`"
        />
      </template>
    </cit-data-table>
  </div>
</template>

<script>
import CitDataTable from "@/components/layout/CitDataTable";
import CreateForm from "./Create.vue";
import ActionDelete from "@/components/layout/ActionButtons/ActionDelete";
import EditForm from "./Edit.vue";

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm,
  },
  data() {
    return {
      headers: [
        {
          text: "Denumire interna",
          value: "internal_name",
          filterType: "text",
        },
        {
          text: "Denumire publica",
          value: "public_name",
        },
        {
          text: "Pret",
          value: "price",
        },
      ],
      category: {},
    };
  },
  created() {
    this.loadCategory();
  },
  methods: {
    loadCategory() {
      this.$http
        .get(`product-extra-options/categories/${this.$route.params.id}`)
        .then(({ data }) => {
          this.category = data;
          this.$store.dispatch("stopLoading");
        })
        .catch(() => {
          this.$vs.notify({
            title: "Eroare",
            text: "Eroare la incarcare, va rugam reimprospatati pagina sau incercati mai tarziu",
            color: "danger",
          });
          this.$store.dispatch("stopLoading");
        });
    },
  },
};
</script>
